import React, {FC} from 'react'
import {Stack, Text, Box, Center, Cluster, PrimaryButton} from '../common'
import {Layout} from '../components/Layout'
import {ZHelmet} from '../components/ZHelmet'

const PrivacyPolicy: FC = props => {
  return (
    <Layout>
      <ZHelmet
        title="Terms and Conditions | best NBFC | Personal Loan | India"
        description="Get complete detail about Terms and Conditions of Zavron Finserv best NBFC or persoanl provider in India just visit page to know more about the Zavron Finserv"
      />
      <Box>
        <Center gutters="var(--s2)" maxWidth="var(--max-width)">
          <Stack space="var(--s2)">
            <Text style={{textAlign: 'left'}} variant="h2">
              Terms and Conditions
            </Text>
            <Stack space="var(--s0)">
              <Text
                variant="h4"
                style={{
                  fontFamily: 'var(--body-font-family)',
                  lineHeight: '150%',
                }}
                color="light">
                ZAVRON FINANCE PRIVATE LIMITED, a Company within the meaning of
                Companies Act, 2013, having registered office at Plot No. 125,
                Maharajbagh Road, Sitabuldi, Nagpur, Maharashtra, 440012, India
                (hereinafter shall be referred to as “ZFPL” or "Company" or "us"
                or "we") is an NBFC registered with the Reserve Bank of India
                (“RBI”) and is the licensed User of the mobile application
                ZinCash ("App") and website www.zavronfinserv.com ("Website")
                (App and Website are hereinafter together referred to as the
                "Platform").
              </Text>
              <Text
                variant="h4"
                style={{
                  fontFamily: 'var(--body-font-family)',
                  lineHeight: '150%',
                }}
                color="light">
                As a user ("User" or "You") of the Platform, You understand that
                Platform provides access between multiple Users in the form of
                consumers, data partners and other borrowers, service providers
                etc. with a view to facilitate lending and borrowing
                activities...(Download the full document by{' '}
                <a
                  href="https://www.zavronfinserv.com/Terms-and-Conditions-Zavron-Finserv.pdf"
                  download="Terms-and-Conditions-Zavron-Finserv.pdf">
                  clicking here
                </a>
                {/* href="./Terms-and-Conditions-Zavron-Finserv.pdf" */}
                )
              </Text>
            </Stack>
          </Stack>
        </Center>
      </Box>
      <Box>
        <Center gutters="var(--s2)" maxWidth="var(--max-width)">
          <Stack space="var(--s0)">
            <Cluster space="var(--s1)" justify="flex-start">
              <PrimaryButton style={{width: 150}}>
                <a
                  style={{fontWeight: 'bold'}}
                  href="https://www.zavronfinserv.com/Terms-and-Conditions-Zavron-Finserv.pdf"
                  download="Terms-and-Conditions-Zavron-Finserv.pdf">
                  Download T&C
                </a>
              </PrimaryButton>
            </Cluster>
          </Stack>
        </Center>
      </Box>
    </Layout>
  )
}

export default PrivacyPolicy
